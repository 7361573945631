<template>
    <v-container>
      <v-row
       justify="center"
       class="pt-5"
      >
      
      <v-card
       max-width="500"
       max-height="500"
       align="center"
       flat   
      >
        
        <v-container  fluid style="background-color: white; ">
        <v-row justify="center">
          <v-img  max-width="450" max-height="150" class="mt-2" src="@/assets/logo_fuelomat.svg"></v-img>
        </v-row>
        <v-row justify="center">
        <p class="white--text text-h5">Login</p> 
        </v-row>
        </v-container>
       
        <v-card-text class="mt-3">
          <v-row>
            <v-alert
              v-model="alert"
              :type="type" 
              dismissible
              width="500" 
            > {{ text }}</v-alert>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                :label="$t('Username')"
                prepend-inner-icon="mdi-account"
                v-model="username"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                prepend-inner-icon="mdi-lock"
                :label="$t('Password')"
                v-model="password"
                type="password"
                v-on:keyup.enter="login"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="#196619"
                dark
                @click="login"
              >{{$t('Login')}}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
     
      </v-row>
    </v-container>
  
</template>

<script>
export default {
  name: "login",

  data() {
    return {
      username: '',
      password: '',
      type: 'error',
      text: '',
      alert: false

    }
  },

  methods: {
    async login() {
      console.log('login')
     
      try {
         await this.$auth.login({
          data: {
              username: this.username,
              password: this.password
          },
          redirect: {name: 'SitesSummary'},
          staySignedIn: true,
          fetchUser: true
        })
        //const res = await this.$http.get("/system")
        //this.$store.commit('SET_STATION', res.data)
      } catch (e) {
        this.alertMsg(this.$t('Login error: Bad password or username'))
      }
    },
    alertMsg(msg) {
      this.type =  'error'
      this.text = msg
      this.alert = true
      setTimeout(()=>{ this.alert = false}, 5000)
    }
  }
}
</script>

<style>

</style>